import { render, staticRenderFns } from "./CardPartnerType.vue?vue&type=template&id=32e3a9aa&scoped=true"
import script from "./CardPartnerType.vue?vue&type=script&lang=js"
export * from "./CardPartnerType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e3a9aa",
  null
  
)

export default component.exports