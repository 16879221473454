<template>
  <div class="card-description">
    <p class="card-title">Тип партнера</p>
    <p class="card-title__small">{{ type }}</p>
  </div>
</template>

<script lang="js">
export default {
  name: 'CardPartnerType',
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
