<template>
  <div class="card-contacts" v-if="webSite">
    <img src="@/assets/images/svg/globus.svg" alt="" class="card-contacts__icon">
    <a :href="webSite" target="_blank" rel="noopener noreferrer" class="card-contacts__text card-contacts__text--pink">
      {{ webSite }}
    </a>
  </div>
</template>

<script lang="js">
export default {
  name: 'CardWebSite',
  props: {
    webSite: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
