<template>
  <div class="card-contacts">
    <p class="card-contacts__text card-contacts__text--small">
      <b>ИНН: </b>{{ inn }}
    </p>
  </div>
</template>

<script lang="js">
export default {
  name: 'CardInn',
  props: {
    inn: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
