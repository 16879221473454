<template>
  <div class="card-header__top">
    <h3 class="card-header__title">{{ title }}</h3>
    <div class="card-header__share">
      <el-tooltip class="item" effect="dark" content="Ссылка скопирована" placement="bottom-end" title="Скопировать"
        :manual="true" :value="tooltip">
        <img src="@/assets/images/svg/share.svg" alt="" @click="copyLink">
      </el-tooltip>
    </div>
    <div v-if="certifications.length">
      <a v-for="(item, index) in certifications" :key="index" :href="item.link" target="_blank"
        rel="noopener noreferrer" class="card-caption">
        Просмотреть сертификат
        <template v-if="certifications.length > 1"> {{ getNameDirection(item.key) }}</template>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CardHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    certifications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tooltip: false
    };
  },
  methods: {
    copyLink() {
      this.tooltip = true;
      const link = window.location.href;
      const input = document.createElement('input');
      input.value = link;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      setTimeout(() => { this.tooltip = false }, 1000);
    },
    getNameDirection(code) {
      return this.directions.find(item => item.code === code).name;
    }
  },
  computed: {
    ...mapGetters('filter', ['directions'])
  }
}
</script>

<style lang="scss" scoped></style>
