<template>
  <ul class="card-socials">
    <li v-for="social in socials" :key="social.link" class="card-socials__item">
      <a :href="social.link" class="socials__link" target="_blank" rel="noopener noreferrer">
        <img class="card-socials__icon" :src="getSocialNetworkImageLink(social.image_link, social.file_id)" alt="">
      </a>
    </li>
  </ul>
</template>

<script lang="js">
export default {
  name: 'CardSocials',
  props: {
    socials: {
      type: Array,
      required: true
    }
  },
  methods: {
    getSocialNetworkImageLink(imageLink, fileId) {
      return !imageLink ? `/api/v1/static/${fileId}/${fileId}.svg` : imageLink;
    }
  }
}
</script>

<style scoped></style>
