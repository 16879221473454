<template>
  <div class="card-contacts" v-if="address">
    <img src="@/assets/images/svg/location.svg" alt="" class="card-contacts__icon">
    <p class="card-contacts__text">{{ address }}</p>
  </div>
</template>

<script lang="js">
export default {
  name: 'CardAddress',
  props: {
    address: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
